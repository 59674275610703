.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  header {
    background-color: #333;
    color: white;
    padding: 10px 20px;
  }
  
  footer {
    background-color: #333;
    color: white;
    padding: 10px 20px;
    text-align: center;
    margin-top: auto;
  }
  
  .main-content {
    flex: 1;
    padding: 20px;
  }