.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.homepage {
  padding: 20px;
}

.pool-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
}

.pool-table {
  min-width: 800px; /* Adjust the width as needed */
  width: 100%;
  border-collapse: collapse;
}

.pool-table th,
.pool-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.pool-table .token-pair:hover {
  cursor: pointer;
  background-color: #f4f4f4;
}

.pool-table th {
  background-color: #f4f4f4;
}

.pool-info h3 {
  margin: 0;
}

.pool-info p {
  margin: 4px 0;
}

.pool-actions button {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.pool-details {
  display: flex;
}

.pool-details-sidebar {
  flex: 0 0 300px;
  background-color: #f4f4f4;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.pool-details-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

.pool-details-sidebar button {
  background-color: 'transparent';
  border: 'none';
  outline: 'none';
  padding: '5px';
}

.pool-details-sidebar button:hover {
  background-color: '#f4f4f4';
  cursor: pointer;
}

.pool-details-sidebar icon {
  font-size: '24px';
  color: '#6c757d';
}

.event-table {
  min-width: 800px; /* Adjust the width as needed */
  width: 100%;
  border-collapse: collapse;
}

.event-table th,
.event-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.event-table th {
  background-color: #f4f4f4;
}

#eventScrollableDiv {
  scrollbar-width: thin;
  scrollbar-color: #888 #f4f4f4;
}

#eventScrollableDiv::-webkit-scrollbar {
  height: 8px;
}

#eventScrollableDiv::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

#eventScrollableDiv::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
