.header {
    display: flex;
    align-items: center;
    background-color: #333;
    color: white;
    padding: 10px 20px;
  }
  
  .logo img {
    height: 60px; /* Adjust the height as needed */
    margin-right: 10px;
  }

  .title-container {
    flex: 1;
    display: flex;
    align-items: center;
  }
  .title-container span {
    font-size: 20px;
    user-select: none;
    color:bisque;
    margin-bottom: 5px;
  }
  
  nav ul {
    list-style: none;
    display: flex;
    margin: 5px 40px;
    padding: 0;
  }
  
  nav ul li {
    margin-right: 20px;
  }
  
  nav ul li a {
    color: white;
    text-decoration: none;
  }
  
  nav ul li a:hover {
    text-decoration: underline;
  }